import React from "react";
import Card from "react-bootstrap/Card";

function CoreSizeDisc({ code }) {
  return (
    <div className="bg-primary bg-opacity-25 p-4 rounded h-100">
      <Card.Title className="mb-1">Additional Information</Card.Title>
      <div>
        <span className="text-primary">{code}</span>
      </div>
    </div>
  );
}

export default CoreSizeDisc;
