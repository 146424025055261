import { memo, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "./SubHeading.scss"


function PrintItemPopup({
    type,
    handleRadioClick,
    handleCancelEvent,
    handleConfirmEvent,
    show
}) 

{


   const content = ["Email","Email and Print", "Print"]
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>Print {type}</div>
      </Modal.Header>
      <Modal.Body>
      <table className="flex-print-order">
        {content.map((item, index) => (
          <tr key={index}>
          <td><input type="radio" name="prints" id={item} onChange={handleRadioClick}></input></td>
          <td>  {item}</td>
          
          </tr>
        ))}
      </table>

      </Modal.Body>
      <Modal.Footer>
      <Button
          variant={"danger"}
          onClick={handleCancelEvent}
        >
          Cancel
        </Button>
        <Button
          variant={ "primary"}
          onClick={handleConfirmEvent}
        >
          Print {type}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(PrintItemPopup);
