import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";
import { getSubOrder } from "../subOrderSlice";
import { getQuote } from "../quoteSlice";
import { setCleaningAll } from "../headerSlice";
import {
  formatCustomers,
  formatCustomer,
  formatVendorCategoryDiscountSchedules,
} from "./customer";

const defaultCustomer = {
  customerId: 0,
  customerName: "",
  contactPhone: "",
  contactName: "",
  contactFax: "",
  billingAdressCombo: "",
  shippingAddressCombo: "",
  aftermarketName: null,
  aftermarketId: 0,
  aftermarketBillingAddress: {},
  billingZipCode: "",
  useShippingRules: true,
  creditcardCharge: 0,
};

export const getAllCustomers = createAsyncThunk(
  "customers/all",
  async (_data, thunkAPI) => {
    try {
      const response = await apiGet.customerList({ page_len: 10 });
      return {
        total: response.data.count,
        data: formatCustomers(response.data.results),
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the customers list.",
      });
    }
  },
);

/**
 * Function to search customers by ID
 * @param {number} id - id of the customer
 */
export const searchCustomersById = createAsyncThunk(
  "customers/searchById",
  async (customer_num, thunkAPI) => {
    try {
      const response = await apiGet.customerList({
        customer_num,
        page_len: 10,
      });
      return {
        total: response.data.count,
        data: formatCustomers(response.data.results),
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error searching the customer by ID.",
      });
    }
  },
);

/**
 * Function to search customers by fuzzy search
 * @param {string} search - advanced search string for customer
 */
export const searchCustomersByFuzzy = createAsyncThunk(
  "customers/searchByFuzzy",
  async (search, thunkAPI) => {
    try {
      const response = await apiGet.customerList({ search, page_len: 10 });
      return {
        total: response.data.count,
        data: formatCustomers(response.data.results),
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error searching the customer by fuzzy.",
      });
    }
  },
);

/**
 * Function to search customers by fuzzy search
 * @param {string} search - advanced search string for customer
 */
export const searchCustomersByAdvanced = createAsyncThunk(
  "customers/searchByAdvanced",
  async (params, thunkAPI) => {
    try {
      const response = await apiGet.customerList({ ...params, page_len: 10 });
      return {
        total: response.data.count,
        data: formatCustomers(response.data.results),
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error searching the customer by fuzzy.",
      });
    }
  },
);

/**
 * Function to get the data of a customer
 * @param {number} id - id of the customer
 */
export const getCustomerById = createAsyncThunk(
  "customer/get",
  async (id, thunkAPI) => {
    try {
      const response = await apiGet.customerById(id);
      return formatCustomer(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting a customer by ID.",
      });
    }
  },
);

export const getCustomersList = createAsyncThunk(
  "customers/getList",
  async (params, thunkAPI) => {
    try {
      const response = await apiGet.customerList(params);

      return {
        total: response.data.count,
        data: formatCustomers(response.data.results),
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the customer list.",
      });
    }
  },
);

/**
 * Function to get the aftermarket data
 * @param {number} aftermarketId - id of the aftermarket
 */
export const getAftermarketById = createAsyncThunk(
  "aftermarket/searchByID",
  async (aftermarketId) => {
    const response = await apiGet.customerById(aftermarketId);
    return {
      id: response.data.customer_num,
      name: response.data.name,
    };
  },
);

export const getCustomerRecentOrders = createAsyncThunk(
  "aftermarket/getCustomerRecentOrders",
  async ({ id }, thunkAPI) => {
    try {
      const res = await apiGet.customerRecentOrders(id);
      if (res.length > 0) {
        return res;
      } else {
        return [];
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the order list.",
      });
    }
  },
);

export const getCustomerRecentQuotes = createAsyncThunk(
  "aftermarket/getCustomerRecentQuotes",
  async ({ id }, thunkAPI) => {
    try {
      const res = await apiGet.customerRecentQuotes(id);
      if (res.length > 0) {
        return res;
      } else {
        return [];
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the quote list.",
      });
    }
  },
);

export const calculateCcCharge = createAsyncThunk(
  "subOrder/calculateCcCharge",
  async ({ customerId, subTotal, terms }, thunkAPI) => {
    try {
      const response = await apiGet.calculateCcCharge({
        subTotal,
        customerId,
        terms,
      });

      return {
        creditcardCharge: response.data
          .find((item) => item.field === "creditcard_charge")
          ?.value.toFixed(2),
        terms: response.data.find((item) => item.field === "terms")?.value,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error calculating credit card charges.",
      });
    }
  },
);

export const vendorDiscounts = createAsyncThunk(
  "customer/vendorDiscounts",
  async (params, thunkAPI) => {
    try {
      const response = await apiGet.customerVendorDiscounts(params);

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting vendor category discount schedules.",
      });
    }
  },
);

export const getDiscountPrograms = createAsyncThunk(
  "customer/getDiscountPrograms",
  async (customerId, thunkAPI) => {
    try {
      const response = await apiGet.discountPrograms(customerId);

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting discount programs.",
      });
    }
  },
);

export const getXrefProducts = createAsyncThunk(
  "customer/getXrefProducts",
  async ({ customerId, code, page }, thunkAPI) => {
    try {
      const response = await apiGet.xrefProducts({ customerId, code, page });

      return {
        total: response.count,
        data: response.results,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting XCrossRef products.",
      });
    }
  },
);

export const customerSlice = createSlice({
  name: "customerSlice",
  initialState: {
    customerId: 0,
    customerName: "",
    customerData: defaultCustomer,
    customersListForId: [],
    customersListForFuzzy: [],
    customers: {
      total: 0,
      data: [],
    },
    discountSchedules: {
      total: 0,
      data: [],
    },
    discountPrograms: [],
    customerReqPo: false,
    freeHandling: false,
    loadedCustomerDetails: false,
    xrefProducts: {
      total: 0,
      data: [],
    },
    total: 0,
  },
  reducers: {
    setCustomerId: (state, action) => ({
      ...state,
      customerId: action.payload,
    }),
    setCustomerName: (state, action) => ({
      ...state,
      customerName: action.payload,
    }),
    setCustomerData: (state, action) => ({
      ...state,
      customerData: action.payload,
    }),
    resetCustomer: (state) => ({
      ...state,
      customerId: 0,
      customerName: "",
      customerData: defaultCustomer,
    }),
    setLoadedCustomerDetails: (state, action) => {
      state.loadedCustomerDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.total = action.payload.total;
        state.customersListForId = action.payload.data;
        state.customersListForFuzzy = action.payload.data;
      })
      .addCase(searchCustomersById.fulfilled, (state, action) => {
        console.log(action.data)
        state.total = action.payload.total;
        state.customersListForId = action.payload.data;
      })
      .addCase(searchCustomersByFuzzy.fulfilled, (state, action) => {
        state.total = action.payload.total;
        state.customersListForFuzzy = action.payload.data;
      })
      .addCase(searchCustomersByAdvanced.fulfilled, (state, action) => {
        state.total = action.payload.total;
        state.customersListForFuzzy = action.payload.data;
      })
      .addCase(getCustomersList.fulfilled, (state, action) => {
        state.customers = action.payload;
      })
      .addCase(getSubOrder.fulfilled, (state, action) => {
        state.customerData = formatCustomer(
          action.payload.subOrderData.customer,
        );
        state.customerId = action.payload.subOrderData.customer_id;
        state.customerName = action.payload.subOrderData.customer.name;
        state.customerReqPo = action.payload.subOrderData.customer.require_po;
        state.freeHandling = action.payload.subOrderData.customer.free_handling;
      })
      .addCase(getQuote.fulfilled, (state, action) => {
        state.customerData = formatCustomer(action.payload.quote.customer);
        state.customerId = action.payload.quote.customer_id;
        state.customerName = action.payload.quote.customer.name;
        state.customerReqPo = action.payload.quote.customer.require_po;
        state.freeHandling = action.payload.quote.customer.free_handling;
      })
      .addCase(getCustomerById.fulfilled, (state, action) => ({
        ...state,
        customerId: action.payload.id,
        customerName: action.payload.name,
        customerData: {
          ...state.customerData,
          ...action.payload,
          customerId: action.payload.id,
        },
        customerReqPo: action.payload.customerReqPo,
        freeHandling: action.payload.freeHandling,
      }))
      .addCase(setCleaningAll, (state) => {
        state.customerId = 0;
        state.customerName = "";
        state.customerData = defaultCustomer;
        state.customerReqPo = false;
        state.freeHandling = false;
      })
      .addCase(calculateCcCharge.fulfilled, (state, action) => ({
        ...state,
        customerData: {
          ...state.customerData,
          creditcardCharge: action.payload.creditcardCharge,
          terms: action.payload.terms,
        },
      }))
      .addCase(vendorDiscounts.fulfilled, (state, action) => ({
        ...state,
        discountSchedules: {
          total: action.payload.count,
          data: formatVendorCategoryDiscountSchedules(action.payload.results),
        },
      }))
      .addCase(getDiscountPrograms.fulfilled, (state, action) => ({
        ...state,
        discountPrograms: action.payload,
      }))
      .addCase(getXrefProducts.fulfilled, (state, action) => {
        state.xrefProducts = action.payload;
      });
  },
});

export const {
  setCustomerId,
  setCustomerName,
  setCustomerData,
  resetCustomer,
  setLoadedCustomerDetails,
} = customerSlice.actions;
export default customerSlice.reducer;
